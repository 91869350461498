<script>
import axios from "axios";

export default {
  name: 'GameTable',
  data() {
    return {
      queryObject: {
        gameId: null,
        name: null,
        author: null,
        releaseTime: null,
        type: null,
        language: null,
        tags: [],
        pageNo: 1,
        pageSize: 25,
        orderBy: 'idDesc'
      },
      makers: [],
      types: [],
      languages: [],
      orderBy: [],
      tableData: [],
      tags: [],
      genres: [],
      totalPage: 0,
      current: 0,
      paginationPager: 5,
      collapse: false,
      showImg: false,
      collapseItems: [],
      total: 0,
      cascaderOptions: {
        multiple: true,
        emitPath: false
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', [new Date(), new Date()]);
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [date, date]);
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [date, new Date()]);
            }
          },
          {
            text: '本月',
            onClick(picker) {
              const date = new Date();
              const year = date.getFullYear();
              const month = date.getMonth();
              const firstDay = new Date(year, month, 1);
              const lastDay = new Date(year, month + 1, 0);
              picker.$emit('pick', [firstDay, lastDay]);
            }
          },
          {
            text: '上月',
            onClick(picker) {
              const date = new Date();
              const year = date.getFullYear();
              const month = date.getMonth();
              const firstDay = new Date(year, month - 1, 1);
              const lastDay = new Date(year, month, 0);
              picker.$emit('pick', [firstDay, lastDay]);
            }
          },
          {
            text: '本季',
            onClick(picker) {
              const date = new Date();
              const year = date.getFullYear();
              const month = date.getMonth();
              let firstDay = null;
              let lastDay = null;
              if (month < 3) {
                firstDay = new Date(year, 0, 1);
                lastDay = new Date(year, 2, 31);
              } else if (month < 6) {
                firstDay = new Date(year, 3, 1);
                lastDay = new Date(year, 5, 30);
              } else if (month < 9) {
                firstDay = new Date(year, 6, 1);
                lastDay = new Date(year, 8, 30);
              } else {
                firstDay = new Date(year, 9, 1);
                lastDay = new Date(year, 11, 31);
              }
              picker.$emit('pick', [firstDay, lastDay]);
            }
          },
          {
            text: '今年',
            onClick(picker) {
              const date = new Date();
              const year = date.getFullYear();
              const firstDay = new Date(year, 0, 1);
              const lastDay = new Date(year, 11, 31);
              picker.$emit('pick', [firstDay, lastDay]);
            }
          },
          {
            text: '去年',
            onClick(picker) {
              const date = new Date();
              const year = date.getFullYear();
              const firstDay = new Date(year - 1, 0, 1);
              const lastDay = new Date(year - 1, 11, 31);
              picker.$emit('pick', [firstDay, lastDay]);
            }
          }
        ]
      }
    }
  },
  methods: {
    getMakerSelect() {
      axios.get('/api/open/select/maker').then(res => {
        this.makers = res.data.data
      })
    },
    getTypeSelect() {
      axios.get('/api/open/select/type').then(res => {
        this.types = res.data.data
      })
    },
    getLanguageSelect() {
      axios.get('/api/open/select/language').then(res => {
        this.languages = res.data.data
      })
    },
    getGenreSelect() {
      axios.get('/api/open/select/genre').then(res => {
        this.genres = res.data.data.genres
      })
    },
    getOrderBy() {
      axios.get('/api/open/select/orderBy').then(res => {
        this.orderBy = res.data.data
      })
    },
    getTableData(pageNo, closeCollapse) {
      if (pageNo !== 0) {
        this.queryObject.pageNo = pageNo
      }
      axios.post('/api/open/table', this.queryObject).then(res => {
        this.tableData = res.data.data.rows
        this.totalPage = res.data.data.pageCount
        this.current = res.data.data.current
        this.total = res.data.data.total
      })

      if (closeCollapse) {
        this.collapseItems = []
        this.resizeTableDelay(250)
      }
    },
    onChange(event, closeCollapse) {
      if (event === null) {
        this.getTableData(1, closeCollapse)
      }
    },
    onGenreChange() {
      if (this.queryObject.tags.length > 5) {
        this.$message({
          message: '最多选择5个分类',
          type: 'warning'
        })
        this.queryObject.tags = []
      }
    },
    resizeTable() {
      let clientHeight = window.innerHeight
      let formHeight = document.querySelector('.top-form').clientHeight;
      let footHeight = document.querySelector('.foot').clientHeight;

      let width = document.documentElement.clientWidth
      if (width < 768) {
        this.paginationPager = 5
        this.collapse = true
      } else if (width < 992) {
        this.paginationPager = 7
        this.collapse = false
      } else if (width < 1200) {
        this.paginationPager = 9
        this.collapse = false
      } else if (width < 1920) {
        this.paginationPager = 11
        this.collapse = false
      } else {
        this.paginationPager = 15
        this.collapse = false
      }

      let table = document.querySelector('.el-table');

      if (this.collapse) {
        setTimeout(() => {
          let collapse = document.querySelector('.collapse').clientHeight
          let page = document.querySelector('.pagination').clientHeight
          let tableHeight = clientHeight - 60 - 20 - 10 - collapse - 10 - footHeight - 20 - page - 20 - 20
          table.style.height = tableHeight + 'px'
        }, 100)
      } else {
        table.style.height = clientHeight - 61 - 20 - formHeight - 20 - 20 - footHeight - 20 - 20 - 10 - 30 + 'px'
      }
    },
    switchChange(status) {
      window.localStorage.setItem('showImg', status ? 'true' : 'false')
    },
    resizeTableDelay(timeout) {
      setTimeout(() => {
        this.resizeTable()
      }, timeout)
    }
  },
  mounted() {
    let showImg = window.localStorage.getItem('showImg');
    if (showImg !== null) {
      this.showImg = showImg === 'true'
    }

    this.getMakerSelect()
    this.getTypeSelect()
    this.getLanguageSelect()
    this.getGenreSelect()
    this.getOrderBy()
    this.getTableData(1, false)
    window.addEventListener('resize', this.resizeTable)
    this.resizeTableDelay(10)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeTable)
  },
  watch: {
    showImg() {
      setTimeout(() => {
        this.$refs.table.doLayout()
      }, 500)
    }
  }
}
</script>

<template>
  <div>
    <div class="query">
      <div v-if="collapse" class="collapse">
        <el-collapse v-model="collapseItems" @change="resizeTableDelay(250)">
          <el-collapse-item name="query">
            <template slot="title">
              <span style="margin-left: 20px;font-size: 16px">搜索...</span>
            </template>
            <el-form :inline="true" :model="queryObject" size="mini" class="top-form">
              <el-form-item label="游戏ID">
                <el-input
                    v-model="queryObject.gameId"
                    placeholder="请输入ID"
                    clearable
                    @clear="getTableData(1, true)"
                    @change="getTableData(1, true)"
                    class="queryForm"
                ></el-input>
              </el-form-item>
              <el-form-item label="游戏名">
                <el-input
                    v-model="queryObject.name"
                    placeholder="请输入游戏名"
                    clearable
                    @clear="getTableData(1, true)"
                    @change="getTableData(1, true)"
                    class="queryForm"
                ></el-input>
              </el-form-item>
              <el-form-item label="社团名">
                <el-select
                    v-model="queryObject.author"
                    filterable
                    clearable
                    placeholder="请选择社团"
                    @clear="getTableData(1, true)"
                    class="queryForm"
                >
                  <el-option
                      v-for="item in makers"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发售日">
                <el-date-picker
                    placeholder="请选择发售日"
                    :editable="false"
                    v-model="queryObject.releaseTime"
                    value-format="timestamp"
                    clearable
                    @change="onChange($event, true)"
                    class="queryForm"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="游戏类型">
                <el-select
                    v-model="queryObject.type"
                    filterable
                    clearable
                    placeholder="请选择游戏类型"
                    @clear="getTableData(1, true)"
                    class="queryForm"
                >
                  <el-option
                      v-for="item in types"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="语言">
                <el-select
                    v-model="queryObject.language"
                    filterable
                    clearable
                    placeholder="请选择语言"
                    @clear="getTableData(1, true)"
                    class="queryForm"
                >
                  <el-option
                      v-for="item in languages"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getTableData(1, true)">GO!</el-button>
              </el-form-item>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </div>
      <el-form :inline="true" :model="queryObject" size="mini" class="top-form" v-else label-width="100px">
        <el-form-item label="游戏id">
          <el-input
              v-model="queryObject.gameId"
              placeholder="游戏ID"
              clearable
              @clear="getTableData(1, false)"
              @change="getTableData(1, false)"
              class="queryForm"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏名">
          <el-input
              v-model="queryObject.name"
              placeholder="游戏名"
              clearable
              @clear="getTableData(1, false)"
              @change="getTableData(1, false)"
              class="queryForm"
          ></el-input>
        </el-form-item>
        <el-form-item label="社团名">
          <el-select
              v-model="queryObject.author"
              filterable
              clearable
              placeholder="社团名"
              @clear="getTableData(1, false)"
              class="queryForm"
          >
            <el-option
                v-for="item in makers"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发售日期">
          <el-date-picker
              :editable="false"
              type="daterange"
              align="right"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              v-model="queryObject.releaseTime"
              value-format="yyyy-MM-dd"
              clearable
              @change="onChange($event, false)"
              class="queryForm"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="游戏类型">
          <el-select
              v-model="queryObject.type"
              filterable
              clearable
              placeholder="游戏类型"
              @clear="getTableData(1, false)"
              class="queryForm"
          >
            <el-option
                v-for="item in types"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="游戏语言">
          <el-select
              v-model="queryObject.language"
              filterable
              clearable
              placeholder="语言"
              @clear="getTableData(1, false)"
              class="queryForm"
          >
            <el-option
                v-for="item in languages"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="游戏分类">
          <el-cascader
              clearable
              placeholder="分类"
              v-model="queryObject.tags"
              :options="genres"
              :props="cascaderOptions"
              :show-all-levels="false"
              :filterable="true"
              class="genre-form"
              @clear="getTableData(1, false)"
              @change="onGenreChange"
          >
            <template slot-scope="{node, data}">
              <span>{{ data.label }}</span>
              <span v-if="!node.isLeaf">({{ data.children.length }})</span>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getTableData(1, false)">GO!</el-button>
        </el-form-item>
      </el-form>
      <el-table
          ref="table"
          :data="tableData"
          style="width: 100%;"
          :height="2"
      >
        <el-table-column
            fixed
            prop="id"
            label="编号"
            width="150">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="left">
              <el-image
                  :src="scope.row.cover"
                  fit="contain"
                  style="width: 250px; height: 188px"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
                <div slot="placeholder">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
              <div slot="reference">{{ scope.row.id }} /
                <span v-if="scope.row.hasDelete">
                  <font-awesome-icon icon="fa-solid fa-ban" style="color: #F56C6C"/> {{ scope.row.gameId }}
                </span>
                <span v-else>
                  <a :href="'https://www.dlsite.com/maniax/work/=/product_id/'+scope.row.gameId+'.html'"
                     target="_blank">{{ scope.row.gameId }}</a>
                </span>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            v-if="showImg"
            label="预览图"
            width="200"
        >
          <template slot-scope="scope">
            <div style="width: 200px; height: 128px">
              <el-image
                  :src="scope.row.cover"
                  fit="contain"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="游戏名"
            width="300">
          <template slot-scope="scope">
            {{ scope.row.name }} <span v-if="scope.row.isAI"><el-tag type="danger" size="small"> AI</el-tag></span>
          </template>
        </el-table-column>
        <el-table-column
            prop="author"
            label="社团名"
            width="200">
          <template slot-scope="scope">
            <a :href="scope.row.authorUrl" target="_blank">{{ scope.row.author }}</a>
          </template>
        </el-table-column>
        <el-table-column
            prop="type"
            label="游戏类型"
            width="250">
          <template slot-scope="scope">
            <el-tag size="mini" style="margin-right: 5px">{{ scope.row.type.split('/')[0] }}</el-tag>
            <el-tag size="mini" style="margin-right: 5px">{{ scope.row.type.split('/')[1] }}</el-tag>
            <el-tag size="mini" style="margin-right: 5px">{{ scope.row.type.split('/')[2] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="releaseTime"
            label="发售日"
            width="100"></el-table-column>
        <el-table-column
            prop="version"
            label="游戏版本"></el-table-column>
        <el-table-column
            prop="language"
            label="语言">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.language === '日语'" type="warning">{{ scope.row.language }}</el-tag>
            <el-tag v-else-if="scope.row.language === '汉语'" type="success">{{ scope.row.language }}</el-tag>
            <el-tag v-else type="danger">{{ scope.row.language }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="translatedName"
            label="翻译名"
            width="200"></el-table-column>
        <el-table-column
            prop="score"
            label="评分"
            width="230">
          <template slot-scope="scope">
            <div style="display: flex; align-content: center">
              <el-rate v-model="scope.row.score"
                       disabled
                       show-score
                       text-color="#606266"
                       :colors="['#99A9BF', '#F7BA2A', '#FF9900']">
              </el-rate>
              <div style="font-size: 14px; line-height: 20px; height: 19px">
                <span>&nbsp;/&nbsp;</span>
                <span v-if="scope.row.scoreDelta > 0" style="color: #508D69">
                  <font-awesome-icon icon="fa-solid fa-arrow-turn-up"/> {{ scope.row.scoreDelta }}
                </span>
                <span v-else-if="scope.row.scoreDelta < 0" style="color: #FF6868">
                  <font-awesome-icon icon="fa-solid fa-arrow-turn-down"/>{{ scope.row.scoreDelta }}
                </span>
                <span v-else style="color: #E6A23C">{{ scope.row.scoreDelta }} </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="saleCount"
            label="销量"
            width="150">
          <template slot-scope="scope">
            {{ scope.row.saleCount }} (<span style="color: #508D69">+{{ scope.row.saleCountDelta }}</span>)
          </template>
        </el-table-column>
        <el-table-column
            prop="price"
            label="售价"></el-table-column>
        <el-table-column
            prop="tags"
            label="分类"
            width="500">
          <template slot-scope="scope">
            <el-tag v-for="tag in scope.row.tags" :key="tag" size="mini" style="margin-right: 5px">{{ tag }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px" class="foot">
        <div class="foot-div-wrapper" style="display: inline-block">
          <div class="foot-div">
            <el-form :inline="true" :model="queryObject" size="mini">
              <el-form-item label="排序依据">
                <el-select v-model="queryObject.orderBy" class="orderBySelect" @change="getTableData(1, false)">
                  <el-option v-for="item in orderBy" :key="item.value" :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="foot-div">
            <el-form :inline="true" :model="queryObject" size="mini">
              <el-form-item label="每页显示">
                <el-select v-model="queryObject.pageSize" class="orderBySelect" @change="getTableData(1, false)">
                  <el-option label="25" value="25"></el-option>
                  <el-option label="50" value="50"></el-option>
                  <el-option label="75" value="75"></el-option>
                  <el-option label="100" value="100"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="foot-div">
            <el-form :inline="true" size="mini">
              <el-form-item label="显示预览">
                <el-switch
                    v-model="showImg"
                    @change="switchChange($event)"
                ></el-switch>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="foot-div-pagination">
          <el-pagination
              class="pagination"
              background
              layout="prev, pager, next, jumper, total"
              :page-count="totalPage"
              :pager-count="paginationPager"
              :current-page="current"
              :total="total"
              :page-size="queryObject.pageSize"
              @current-change="getTableData($event, false)"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.el-rate {
  display: inline-block;
}

.orderBySelect {
  width: 125px;
}

a {
  color: #606266;
  text-decoration: none;
}

/deep/ .el-table, .el-table__expanded-cell {
  background-color: transparent !important;
}

/deep/ .el-table tr {
  background-color: transparent;
}

.foot-div-pagination {
  float: right;
  right: 10px;
}

.foot-div {
  display: inline-block;
}

.top-form {
  padding-left: 10px;
  margin-top: 10px;
}

.foot-div-wrapper {
  padding-left: 10px;
}

.el-table {
  margin-top: 10px;
}

.genre-form {
  width: 500px;
}
</style>
