<script>
export default {
  name: 'LanguageCharts',
  props: ['data'],
  mounted() {
    let myChart = this.$echarts.init(document.querySelector('.language-box'))
    myChart.setOption({
      title: {
        text: '语言分布',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: 'right'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: '50%',
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    })

    setTimeout(() => {
      myChart.setOption({
        series: [
          {
            data: this.data
          }
        ]
      })
    }, 1000)
  }
}
</script>

<template>
  <div>
    <div class="language-box">

    </div>
  </div>
</template>

<style scoped>
.language-box {
  width: 100%;
  aspect-ratio: 3 / 2;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}
</style>
