<script>
export default {
  name: 'YearCountCharts',
  props: ['data'],
  mounted() {
    console.log(this.data)
    let myChart = this.$echarts.init(document.querySelector('.year-count-box'))
    myChart.setOption({
      title: {
        text: '每年游戏数量',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Direct',
          type: 'bar',
          barWidth: '50%',
          itemStyle: {
            barBorderRadius: [50, 50, 0, 0]
          }
        }
      ]
    })

    setTimeout(() => {
      myChart.setOption({
        xAxis:[
          {
            data: this.data.map(item => item.name)
          }
        ],
        series: [
          {
            data: this.data.map(item => item.value)
          }
        ]
      })
    }, 1000)
  }
}
</script>

<template>
  <div>
    <div class="year-count-box">
    </div>
  </div>
</template>

<style scoped>
.year-count-box {
  width: 100%;
  aspect-ratio: 3 / 2;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}
</style>
