<script>
import GameTypeCharts from "@/components/charts/GameTypeCharts.vue";
import LanguageCharts from "@/components/charts/LanguageCharts.vue";
import ScoreCharts from "@/components/charts/ScoreCharts.vue";
import YearCountCharts from "@/components/charts/YearCountCharts.vue";
import axios from "axios";

export default {
  name: 'GameStatistics',
  components: {GameTypeCharts, LanguageCharts, ScoreCharts, YearCountCharts},
  data() {
    return {
      statistics: {
        scores: [],
        gameType: {
          type: [],
          counts: []
        },
        languages: [
          {
            "value": 0,
            "name": ""
          }
        ],
        years: [
          {
            "value": 0,
            "name": ""
          }
        ]
      }
    }
  },
  beforeCreate() {
    axios.get('/api/open/statistics').then(res => {
      this.statistics = res.data.data
    })
  }
}
</script>

<template>
  <div>
    <el-row :gutter="20" style="margin-bottom: 20px">
      <el-col :xs="24" :xl="12">
        <game-type-charts :type.sync="statistics.gameType.type" :count.sync="statistics.gameType.counts"></game-type-charts>
      </el-col>
      <el-col :xs="24" :xl="12">
        <score-charts :data.sync="statistics.scores"></score-charts>
      </el-col>
      <el-col :xs="24" :xl="12">
        <language-charts :data.sync="statistics.languages"></language-charts>
      </el-col>
      <el-col :xs="24" :xl="12">
        <year-count-charts :data.sync="statistics.years"></year-count-charts>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-col {
  margin-bottom: 20px;
}
</style>
