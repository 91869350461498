import { render, staticRenderFns } from "./GameTypeCharts.vue?vue&type=template&id=050b87e5&scoped=true"
import script from "./GameTypeCharts.vue?vue&type=script&lang=js"
export * from "./GameTypeCharts.vue?vue&type=script&lang=js"
import style0 from "./GameTypeCharts.vue?vue&type=style&index=0&id=050b87e5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "050b87e5",
  null
  
)

export default component.exports