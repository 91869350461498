<script>
export default {
  name: 'ScoreCharts',
  props: ['data'],
  mounted() {
    let myChart = this.$echarts.init(document.querySelector('.score-box'))
    myChart.setOption({
      title: {
        text: '评分分布',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['×', '<3.5', '<3.6', '<3.7', '<3.8', '<3.9', '<4.0', '<4.1', '<4.2', '<4.3', '<4.4', '<4.5', '<4.6', '<4.7', '<4.8', '<4.9', '<5.0', '=5.0']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          type: 'line',
          smooth: true
        }
      ]
    })

    setTimeout(() => {
      myChart.setOption({
        series: [
          {
            data: this.data
          }
        ]
      })
    }, 1000)
  }
}
</script>

<template>
  <div>
    <div class="score-box">

    </div>
  </div>
</template>

<style scoped>
.score-box {
  width: 100%;
  aspect-ratio: 3 / 2;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}
</style>
