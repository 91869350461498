<script>
export default {
  name: 'GameTypeCharts',
  props: ['type', 'count'],
  mounted() {
    let myChart = this.$echarts.init(document.querySelector('.type-box'))
    myChart.setOption({
      title: {
        text: '游戏类型',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Direct',
          type: 'bar',
          barWidth: '50%',
          itemStyle: {
            barBorderRadius: [50, 50, 0, 0]
          }
        }
      ]
    })

    setTimeout(() => {
      myChart.setOption({
        xAxis: [
          {
            data: this.type
          }
        ],
        series: [
          {
            data: this.count
          }
        ]
      })
    }, 1000)
  }
}
</script>

<template>
  <div>
    <div class="type-box">

    </div>
  </div>
</template>

<style scoped>
.type-box {
  width: 100%;
  aspect-ratio: 3 / 2;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}
</style>
